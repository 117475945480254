<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20">
      <FormItem>
        <span>仓库名称：</span>
        <!--        <i-input v-model="warehouse_name" placeholder="请输入" class="iviewIptWidth250"></i-input>-->
        <Select class="iviewIptWidth250" v-model="warehouse_name" clearable filterable>
          <Option v-for="item in storeList" :value="item.warehouse_name" :key="item.warehouse_id + item.warehouse_name">{{ item.warehouse_name }}</Option>
        </Select>
      </FormItem>
      <FormItem class="po-create-number" :label-width="10">
        <span class="pageBtn finger btnSure" @click="query">查询</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="addPersons" v-if="insertAble">新增</span>
      </FormItem>
    </Form>
    <Table :productList="listColumns" :productData="listData" :total="total" :pages="pages" @change-page="changePage" :loading="isLoad" :isLoad="isLoad" totalText="条记录"></Table>
    <Modal v-model="addState" :title="title" class-name="vertical-center-modal" width="626" :mask-closable="false">
      <div v-if="addState">
        <div class="clearfix">
          <div class="item">
            <span>仓库名称：</span>
            <i-input v-model.trim="from.warehouse_name" placeholder="请输入" maxlength="20"></i-input>
          </div>
          <div class="item">
            <span>联系人：</span>
            <i-input v-model.trim="from.contact_name" placeholder="请输入" maxlength="10"></i-input>
          </div>
          <div class="item">
            <span>联系电话：</span>
            <i-input v-model.trim="from.phone" placeholder="请输入" maxlength="11"></i-input>
          </div>
          <div class="item">
            <span>仓库地址：</span>
            <i-input v-model.trim="from.address" placeholder="请输入"></i-input>
          </div>
          <!--          <div class="item"><span>仓库特性：</span><i-input v-model.trim="from.warehouse_character" placeholder="请输入"></i-input></div>-->
          <div class="item">
            <span>仓库特性：</span>
            <Select v-model="from.warehouse_character" clearable filterable @on-change="characterChange">
              <Option v-for="item in characterList" :value="item.character_desc" :key="item.character_desc + item.character_id">{{ item.character_desc }}</Option>
            </Select>
          </div>
          <div class="item">
            <span>温度区间：</span>
            <i-input readonly v-model.trim="from.warehouse_temperature" placeholder="请输入"></i-input>
          </div>
          <div class="item">
            <span>湿度区间：</span>
            <i-input readonly v-model.trim="from.warehouse_humidity" placeholder="请输入"></i-input>
          </div>
          <div class="item">
            <span>所属客户：</span>
            <Select v-model="custumerIndex" clearable filterable @on-change="customerChange">
              <Option v-for="(item, index) in custumerList" :value="index" :key="index">{{ item.customer_name }}</Option>
            </Select>
          </div>
        </div>
      </div>
      <div slot="footer">
        <span v-if="title === '新增'" class="pageBtn finger btnSure" @click="ok">确定</span>
        <span v-else class="pageBtn finger btnSure" @click="changeRole">确定</span>
      </div>
    </Modal>
    <tips-component showModal="delete" v-if="deleteState" @cancleBtn="deleteState = false" @sureBrn="sureDetele"></tips-component>
    <tips-component showModal="ban" v-if="banState" @cancleBtn="banState = false" @sureBrn="sureBan(1)"></tips-component>
    <tips-component showModal="start" v-if="startState" @cancleBtn="startState = false" @sureBrn="sureBan(-1)"></tips-component>
  </div>
</template>
<script>
import Table from '@/components/table'
import TipsComponent from '@/components/tipsComponent'
export default {
  components: {
    Table,
    TipsComponent,
  },
  data() {
    return {
      startState: false,
      custumerIndex: null,
      isLoad: true,
      warehouse_name: '',
      total: 22,
      pages: {
        page: 1,
        rows: 10,
      },
      addState: false,
      listData: [],
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 68,
        },
        {
          title: '仓库名称',
          key: 'warehouse_name',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '联系人',
          key: 'contact_name',
          align: 'center',
          width: 150,
        },
        {
          title: '联系电话',
          key: 'phone',
          align: 'center',
          width: 150,
        },
        {
          title: '仓库地址',
          key: 'address',
          align: 'center',
          minWidth: 280,
        },
        {
          title: '仓库特性',
          key: 'warehouse_character',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '温度区间',
          key: 'warehouse_temperature',
          align: 'center',
          width: 150,
        },
        {
          title: '湿度区间',
          key: 'warehouse_humidity',
          align: 'center',
          width: 150,
        },
        {
          title: '所属客户',
          key: 'customer_name',
          align: 'center',
          width: 150,
        },
        {
          title: '操作',
          align: 'center',
          width: 200,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    marginRight: '20px',
                    color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer',
                  },
                  class: {
                    disableUse: this.modifyAble,
                  },
                  on: {
                    click: () => {
                      this.change(param.row)
                    },
                  },
                },
                '修改'
              ),
              h(
                'span',
                {
                  style: {
                    marginRight: '20px',
                    color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer',
                  },
                  class: {
                    disableUse: this.deleteAble,
                  },
                  on: {
                    click: () => {
                      this.banStore(param.row.warehouse_id, param.row.state)
                    },
                  },
                },
                param.row.state == 1 ? '禁用' : '启用'
              ),
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer',
                  },
                  class: {
                    disableUse: this.deleteAble,
                  },
                  on: {
                    click: () => {
                      this.deleteRow(param.row)
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      from: {
        warehouse_name: '',
        contact_name: '',
        phone: '',
        address: '',
        warehouse_character: '',
        warehouse_temperature: '',
        warehouse_humidity: '',
        customer_info: {
          customer_id: '',
          customer_type: '',
        },
      },
      title: '新增',
      deleteState: false,
      banState: false,
      deleteId: '',
      wareId: '',
      storeList: [],
      characterList: [], // 特性list
      custumerList: [], // 客户list
      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false, // 查找权限
    }
  },
  mounted() {
    this.queryList()
    this.queryStore()
    this.getRightVisibal()
    this.queryQermissionWarehouseCharacter()
    this.getCustomer()
  },
  methods: {
    banStore(id, type) {
      this.wareId = id
      if (type == 1) {
        this.banState = true
      } else {
        this.startState = true
      }
    },
    // 获取下拉
    getCustomer() {
      this.$http.get(this.$api.customerList, true).then(res => {
        this.custumerList = res.data
      })
    },
    customerChange(e) {
      console.log(e)
      console.log(this.custumerIndex)
      console.log(this.custumerList)
      if (!e && e != 0) {
        this.custumerIndex = null
        this.from.customer_info = {
          customer_id: '',
          customer_type: '',
        }
      } else {
        this.from.customer_info.customer_id = this.custumerList[this.custumerIndex].customer_id
        this.from.customer_info.customer_type = this.custumerList[this.custumerIndex].customer_type
      }
      console.log(this.from)
    },
    characterChange(e) {
      console.log(e)
      if (!e) {
        this.from.warehouse_character = ''
        this.from.warehouse_temperature = ''
        this.from.warehouse_humidity = ''
      } else {
        for (let i = 0; i < this.characterList.length; i++) {
          if (this.from.warehouse_character == this.characterList[i].character_desc) {
            this.from.warehouse_temperature = this.characterList[i].warehouse_temperature
            this.from.warehouse_humidity = this.characterList[i].warehouse_humidity
          }
        }
      }
    },
    queryQermissionWarehouseCharacter() {
      console.log('试试')
      this.$http.get(this.$api.permissionWarehouseCharacter, {}, true).then(res => {
        this.characterList = res.data
      })
    },
    queryStore() {
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
      }
      this.$http.get(this.$api.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    // 点击查询
    query() {
      this.pages.page = 1
      this.queryList()
    },
    // 查询列表
    queryList() {
      this.isLoad = true
      let data = {
        warehouse_name: this.warehouse_name,
        page: this.pages.page,
        rows: this.pages.rows,
        permission_page: '1',
      }
      this.$http.get(this.$api.permissionWarehouseList, data, true).then(res => {
        this.isLoad = false
        this.listData = res.data
        this.total = res.total
      })
    },
    // 确认添加
    ok() {
      console.log(this.from)
      if (!this.from.warehouse_name || !this.from.contact_name || !this.from.phone || !this.from.address || !this.from.warehouse_character || !this.from.warehouse_temperature || !this.from.warehouse_humidity) {
        this.$Message.warning('请完善仓库信息再提交')
        return
      }
      let re = /^1\d{10}$/
      if (!re.test(this.from.phone)) {
        this.$Message.warning('请输入11位的电话号码')
        return
      }
      if (!this.from.customer_info.customer_id || !this.from.customer_info.customer_type) {
        delete this.from.customer_info
      }
      this.$http.post(this.$api.permissionWarehouseList, this.from, true).then(res => {
        this.from = {
          warehouse_name: '',
          contact_name: '',
          phone: '',
          address: '',
          warehouse_character: '',
          warehouse_temperature: '',
          warehouse_humidity: '',
          customer_info: {
            customer_id: '',
            customer_type: '',
          },
        }
        this.custumerIndex = null
        this.addState = false
        this.$Message.success('新增仓库成功')
        this.pages.page = 1
        this.queryList()
        this.queryStore()
      })
    },
    changeRole(name) {
      if (!this.from.warehouse_name || !this.from.contact_name || !this.from.phone || !this.from.address || !this.from.warehouse_character || !this.from.warehouse_temperature || !this.from.warehouse_humidity) {
        this.$Message.warning('请完善仓库信息再提交')
        return
      }
      let re = /^1\d{10}$/
      if (!re.test(this.from.phone)) {
        this.$Message.warning('请输入11位的电话号码')
        return
      }
      if (!this.from.customer_info.customer_id || !this.from.customer_info.customer_type) {
        delete this.from.customer_info
      }
      this.$http.Put(this.$api.permissionWarehouse, this.from.warehouse_id, this.from, true).then(res => {
        this.from = {
          warehouse_name: '',
          contact_name: '',
          phone: '',
          address: '',
          warehouse_character: '',
          warehouse_temperature: '',
          warehouse_humidity: '',
          customer_info: {
            customer_id: '',
            customer_type: '',
          },
        }
        this.custumerIndex = null
        this.addState = false
        this.pages.page = 1
        this.queryList()
        this.queryStore()
      })
    },
    addPersons() {
      this.title = '新增'
      this.from = {
        warehouse_name: '',
        contact_name: '',
        phone: '',
        address: '',
        warehouse_character: '',
        warehouse_temperature: '',
        warehouse_humidity: '',
        customer_info: {
          customer_id: '',
          customer_type: '',
        },
      }
      this.custumerIndex = null
      this.addState = true
    },
    changePage(e) {
      this.pages.page = e
      this.queryList()
    },
    // 修改
    change(row) {
      this.title = '修改'
      this.from = JSON.parse(JSON.stringify(row))
      this.from.customer_info = {}
      this.custumerList.forEach((item, index) => {
        if (item.customer_id === row.customer_id && item.customer_type == row.customer_type) {
          this.from.customer_info.customer_id = row.customer_id
          this.from.customer_info.customer_type = row.customer_type
          this.custumerIndex = index
        }
      })
      console.log(this.from)
      this.addState = true
    },
    // 删除
    deleteRow(row) {
      this.deleteState = true
      this.deleteId = row.warehouse_id
    },
    sureDetele() {
      this.$http.Delete(this.$api.permissionWarehouse, this.deleteId, true).then(res => {
        this.deleteState = false
        this.pages.page = 1
        this.queryList()
        this.queryStore()
      })
    },
    sureBan(type) {
      let data = {
        warehouse_id: this.wareId,
        state: type == 1 ? '-1' : '1',
      }
      this.$http.put(this.$api.permissionWareState, data).then(res => {
        this.banState = false
        this.startState = false
        this.pages.page = 1
        this.queryList()
        this.queryStore()
      })
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = !rightStr.delete
      this.insertAble = rightStr.insert
      this.modifyAble = !rightStr.modify
      this.searchAble = rightStr.search
    },
  },
}
</script>
<style scoped lang="less">
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .ivu-modal {
    top: 0;
  }
}
.item {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  span {
    width: 130px;
    color: #000;
    text-align: justify;
    height: 32px;
    line-height: 32px;
  }
  span::after {
    content: '';
    display: inline-block;
    padding-left: 100%;
  }
}
/deep/ .disableUse {
  display: none;
  position: absolute;
}
</style>
